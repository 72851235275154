var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('ValidationObserver',{ref:"validation-observer"},[_c('b-row',[_c('b-col',{staticClass:"px-1",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Название [RU]","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название [RU]","label-for":"name-ru"}},[_c('b-form-input',{attrs:{"id":"name-ru","size":"md","placeholder":"Введите"},model:{value:(_vm.name.ru),callback:function ($$v) {_vm.$set(_vm.name, "ru", $$v)},expression:"name.ru"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('b-col',{staticClass:"px-1",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Название [UZ]","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название [UZ]","label-for":"name-uz"}},[_c('b-form-input',{attrs:{"id":"name-uz","size":"md","placeholder":"Введите"},model:{value:(_vm.name.uz),callback:function ($$v) {_vm.$set(_vm.name, "uz", $$v)},expression:"name.uz"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"px-1",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Описание [RU]","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Описание [RU]","label-for":"body-ru"}},[_c('b-form-input',{attrs:{"id":"body-ru","size":"md","placeholder":"Введите"},model:{value:(_vm.body.ru),callback:function ($$v) {_vm.$set(_vm.body, "ru", $$v)},expression:"body.ru"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('b-col',{staticClass:"px-1",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Описание [UZ]","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Описание [UZ]","label-for":"body-uz"}},[_c('b-form-input',{attrs:{"id":"body-uz","size":"md","placeholder":"Введите"},model:{value:(_vm.body.uz),callback:function ($$v) {_vm.$set(_vm.body, "uz", $$v)},expression:"body.uz"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1),_c('hr'),_c('b-row',[_c('b-col',{staticClass:"px-1",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Цена","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Цена","label-for":"price"}},[_c('b-form-input',{attrs:{"type":"number","id":"price","size":"md","placeholder":"Введите"},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('b-col',{staticClass:"px-1",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Цена со скидкой","label-for":"discount_price"}},[_c('b-form-input',{attrs:{"type":"number","id":"discount_price","size":"md","placeholder":"Введите"},model:{value:(_vm.discount_price),callback:function ($$v) {_vm.discount_price=$$v},expression:"discount_price"}})],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{staticClass:"px-1",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Количество","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Количество","label-for":"count"}},[_c('b-form-input',{attrs:{"type":"number","id":"count","size":"md","placeholder":"Введите"},model:{value:(_vm.count),callback:function ($$v) {_vm.count=$$v},expression:"count"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Единица измерения","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Единица измерения","label-for":"unit"}},[_c('b-form-select',{attrs:{"id":"unit","options":_vm.unitOptions},model:{value:(_vm.unitId),callback:function ($$v) {_vm.unitId=$$v},expression:"unitId"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Изображение"}},[_c('VueFileAgent',{ref:"vueFileAgent",attrs:{"deletable":true,"meta":true,"accept":'image/*,.zip',"maxSize":'5MB',"maxFiles":5,"helpText":'Выберите изображение',"errorText":{
                                    type: 'Неверный тип файла. Разрешены только изображения или zip',
                                    size: 'Размер файла не должен превышать 5 МБ.',
                                }},on:{"beforedelete":function($event){return _vm.onBeforeDelete($event)}},model:{value:(_vm.fileRecords),callback:function ($$v) {_vm.fileRecords=$$v},expression:"fileRecords"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Видео"}},[_c('VueFileAgent',{ref:"vueVideoAgent",attrs:{"deletable":true,"meta":true,"accept":'video/*',"maxSize":'5MB',"maxFiles":5,"helpText":'Выберите Видео',"errorText":{
                                type: 'Неверный тип файла. Разрешены только Видео',
                                size: 'Размер файла не должен превышать 5 МБ.',
                            }},on:{"beforedelete":function($event){return _vm.onBeforeVideoDelete($event)}},model:{value:(_vm.videosRecords),callback:function ($$v) {_vm.videosRecords=$$v},expression:"videosRecords"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Категория","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Категория","label-for":"category"}},[_c('b-form-select',{attrs:{"id":"category","options":_vm.categoryOptions},model:{value:(_vm.categoryId),callback:function ($$v) {_vm.categoryId=$$v},expression:"categoryId"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1),(_vm.categorySpecs.length > 0)?_c('b-form-group',{attrs:{"label":"Характеристики","label-class":"font-weigth-bold"}},_vm._l((_vm.categorySpecs),function(spec){return _c('b-row',{},[_c('b-col',{staticClass:"px-1",attrs:{"cols":"12","md":"6"}},[(spec.type === 'text')?[_c('ValidationProvider',{attrs:{"name":spec.name.ru,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":spec.name.ru,"label-for":("specification-" + (spec.id))}},[_c('b-form-input',{attrs:{"id":("specification-" + (spec.id)),"placeholder":"Введите"},model:{value:(_vm.specValues[spec.id]),callback:function ($$v) {_vm.$set(_vm.specValues, spec.id, $$v)},expression:"specValues[spec.id]"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})]:_vm._e(),(spec.type === 'checkbox')?[_c('b-form-checkbox',{attrs:{"id":("specification-" + (spec.id))},model:{value:(_vm.specValues[spec.id]),callback:function ($$v) {_vm.$set(_vm.specValues, spec.id, $$v)},expression:"specValues[spec.id]"}},[_vm._v(" "+_vm._s(spec.name.ru))])]:_vm._e(),(spec.type === 'select')?[_c('ValidationProvider',{attrs:{"name":spec.name.ru,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":spec.name.ru,"label-for":("specification-" + (spec.id))}},[_c('b-form-select',{attrs:{"id":("specification-" + (spec.id))},model:{value:(_vm.specValues[spec.id]),callback:function ($$v) {_vm.$set(_vm.specValues, spec.id, $$v)},expression:"specValues[spec.id]"}},[_c('b-form-select-option',{attrs:{"disabled":"","value":undefined}},[_vm._v("Выберите характеристику ")]),_vm._l((spec.options),function(opt){return _c('b-form-select-option',{attrs:{"value":+opt.id}},[_vm._v(" "+_vm._s(opt.value.ru)+" ")])})],2)],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})]:_vm._e()],2)],1)}),1):_vm._e(),_c('b-form-group',{staticClass:"my-1",attrs:{"label":"Выбранные авто:","label-class":"font-weigth-bold"}},[_c('b-row',{staticClass:"pl-1"},[_c('b-col',[(_vm.relations.length === 0)?[_vm._v(" Ничего не выбрано ")]:_vm._e(),_vm._l((_vm.relations),function(bmy){return _c('b-row',{staticClass:"px-1 my-1"},[_c('b-col',{staticClass:"d-flex justify-content-between align-items-center",attrs:{"cols":"12","md":"6"}},[_vm._v(" "+_vm._s(bmy.text)+" "),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'
                                    ),expression:"'rgba(113, 102, 240, 0.15)'\n                                    ",modifiers:{"400":true}}],staticClass:"delete__btn",attrs:{"variant":"outline-danger","size":"sm"},on:{"click":function (e) { return _vm.removeRelation(bmy.text); }}},[_c('feather-icon',{attrs:{"icon":"MinusCircleIcon","size":"14"}})],1)],1)],1)})],2)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('b-form-select',{staticClass:"mb-1",attrs:{"options":_vm.brandOptions},model:{value:(_vm.brandId),callback:function ($$v) {_vm.brandId=$$v},expression:"brandId"}})],1),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('b-form-select',{staticClass:"mb-1",attrs:{"disabled":!_vm.brandId,"options":_vm.modelOptions},model:{value:(_vm.modelId),callback:function ($$v) {_vm.modelId=$$v},expression:"modelId"}})],1),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('b-form-select',{staticClass:"mb-1",attrs:{"disabled":!_vm.modelId,"options":_vm.yearOptions},model:{value:(_vm.yearId),callback:function ($$v) {_vm.yearId=$$v},expression:"yearId"}})],1),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right mr-1",attrs:{"variant":"outline-success","disabled":!_vm.yearId},on:{"click":_vm.addRelation}},[_vm._v(" Добавить ")])],1)],1)],1)],1),_c('b-button',{staticClass:"btn-success float-right mt-2 mr-1",attrs:{"disabled":_vm.isSaving},on:{"click":_vm.saveProduct}},[_vm._v(" Сохранить ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }